@import '../../../node_modules/bulma/bulma.sass';

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Custom styles
tr {
  font-size: 12px;
}
.table td {
  vertical-align: middle;
}
select {
  width: 180px;
}
.full {
  width: 100%;
}
.empty {
  height: 50px;
  text-align: center;
}

.analytics {
  tr {
    font-size: 10px;
    line-height: 10px;
  }
  td {
    vertical-align: middle;
    text-align: right;
    /* min-width: 70px;
    max-width: 100px; */
    /* width: 70px; */
  }
  td,
  th {
    width: 80px;
  }
  select {
    width: 180px;
  }
  .full {
    width: 100%;
  }
  .empty {
    height: 50px;
    text-align: center;
  }
  .chart-container {
    height: 300px;
  }
}